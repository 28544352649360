import { ready } from '#js/components/utils'

ready(function () {
  document.querySelectorAll('[data-disable-after-submit=true]').forEach(function (form) {
    form.addEventListener('submit', function (event) {
      event.target.querySelectorAll('input:not([type=submit])').forEach(element => {
        element.readOnly = true
      })
      event.target.querySelectorAll(
        'button, input[type=submit], input[type=reset], input[type=button]'
      ).forEach(element => {
        element.disabled = true
      })
    })
  })
})
